import { useForm } from '@partstech/ui/forms';
import { isEmpty } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { SupplierAccount } from 'entities/supplierAccount';
import { useAddSupplierAccount, useUpdateSupplierAccount } from 'hooks/supplierAccounts';
import { useFormStatus } from 'shared/lib/form';
import { getRequiredFields, normalizeFieldValue } from 'utils';
import type { CredentialValues } from '../../../../types';
import type { Store } from 'entities/store';

type Props = {
  account?: SupplierAccount;
  store?: Store;
  onSubmit: (store: Store) => void;
};

export const useEditForm = ({ account, store, onSubmit }: Props) => {
  const supplier = store?.supplier ?? null;

  const credentialFields = useMemo(() => {
    const fields = supplier?.credentialFields ?? [];

    return supplier?.isTire ? getRequiredFields(fields) : fields;
  }, [supplier?.credentialFields, supplier?.isTire]);
  const additionalInformation = store?.additionalInformation;

  const defaultCredentials = useMemo(
    () => account?.credentials ?? normalizeFieldValue(supplier?.credentialFields ?? []),
    [account?.credentials, supplier?.credentialFields]
  );

  const defaultAdditionalInformation = useMemo(
    () => account?.additionalInformation ?? normalizeFieldValue(supplier?.additionalInformation?.fields ?? []),
    [account?.additionalInformation, supplier?.additionalInformation?.fields]
  );

  const form = useForm<CredentialValues>({
    defaultValues: {
      credentials: defaultCredentials,
      additionalInformation: defaultAdditionalInformation,
      storeId: store?.id ?? undefined,
    },
  });

  const { watch } = form;
  const formValues = watch();

  const [addAccount, addAccountStatus] = useAddSupplierAccount();
  const [updateAccount, updateAccountStatus] = useUpdateSupplierAccount(store?.id !== formValues.storeId);

  const isLoading = addAccountStatus.isLoading || updateAccountStatus.isLoading;

  useFormStatus({ form, status: account ? updateAccountStatus : addAccountStatus });

  const hasFilledCredentials = useCallback(() => {
    const credentialValues = getRequiredFields(credentialFields).map(({ internalName }) =>
      formValues.credentials[internalName]?.trim()
    );

    if (supplier?.isTire) {
      return !credentialValues.some(isEmpty);
    }

    const hasFilledAdditionalInformation = !Object.values(formValues.additionalInformation ?? {}).some(isEmpty);

    return !credentialValues.every(isEmpty) && hasFilledAdditionalInformation;
  }, [credentialFields, formValues, supplier?.isTire]);

  const disabledSubmitButton = useMemo(() => {
    if (!supplier?.hasCredentials) {
      return false;
    }

    return isLoading || !hasFilledCredentials();
  }, [hasFilledCredentials, isLoading, supplier]);

  const submitForm = useCallback(
    async (values: CredentialValues) => {
      const modifiedAccount = account
        ? await updateAccount({ ...values, accountId: account.id }, account)
        : await addAccount({ ...values, supplierId: supplier?.id ?? '' });

      if (!(modifiedAccount instanceof SupplierAccount) || !modifiedAccount.store) {
        return;
      }

      onSubmit(modifiedAccount.store);
    },
    [account, addAccount, onSubmit, supplier, updateAccount]
  );

  return {
    form,
    submitForm,
    isLoading,
    disabledSubmitButton,
    credentialFields,
    additionalInformation,
  };
};
