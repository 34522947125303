import { Banner, Box, Button, ImageFallback, Link, Scrollbar, useMedia } from '@partstech/ui';
import { sortBy } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { Title } from 'components/OptionList/Title';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { VehicleRequiredView } from 'components/VehicleRequiredView';
import { Routes } from 'constant';
import { useGetCustomJobs } from 'entities/customJob';
import { usePermissions } from 'entities/permission';
import { PaidFeatureAlias, useLearnAboutFeatureModal } from 'features/subscription';
import jobsImg from 'images/icons/search-tabs/jobs.svg';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { JobType } from 'models';
import { FreeJobs } from './FreeJobs';
import { VehicleSpecificJobsLabel } from './VehicleSpecificJobsLabel';
import type { Job } from 'models';

type Props = {
  hasVehicle?: boolean;
  options: Job[];
  onChange: (option: Job) => void;
  onSelect?: (option: Job) => void;
  onSelectVehicleClick?: () => void;
  isKeyboardDisabled?: boolean;
};

export const JobsList = ({
  hasVehicle = false,
  isKeyboardDisabled,
  onChange,
  onSelect,
  onSelectVehicleClick,
  options,
}: Props) => {
  const { isMobile } = useMedia();

  const {
    shop: { jobsAllowed },
  } = usePermissions();

  const {
    flags: { vehicleSpecificJobs: vehicleSpecificJobsEnabled, customJobs: customJobsEnabled },
  } = useLaunchDarkly();

  const { open: openLearnAboutJobsModal } = useLearnAboutFeatureModal({ feature: PaidFeatureAlias.Jobs });
  const { open: openLearnAboutCustomJobsModal } = useLearnAboutFeatureModal({ feature: PaidFeatureAlias.CustomJobs });

  const { customJobs: allCustomJobs } = useGetCustomJobs({ skip: !jobsAllowed });

  const selectJob = useCallback(
    (job: Job) => {
      if (job.isPaid && !jobsAllowed) {
        openLearnAboutJobsModal();
        return;
      }

      onChange(job);
    },
    [jobsAllowed, onChange, openLearnAboutJobsModal]
  );

  const handleOpen = useCallback(() => {
    openLearnAboutCustomJobsModal();
  }, [openLearnAboutCustomJobsModal]);

  const customJobs = useMemo(
    () =>
      sortBy(
        options.filter((job) => job.type === JobType.Custom),
        'priority'
      ).reverse(),
    [options]
  );

  const freeJobs = useMemo(
    () => (jobsAllowed ? [] : options.filter((job) => job.isFree && job.type !== JobType.Custom)),
    [jobsAllowed, options]
  );

  const paidJobs = useMemo(
    () =>
      jobsAllowed
        ? options.filter((job) => job.type !== JobType.Custom)
        : options.filter((job) => job.isPaid && job.type !== JobType.Custom),
    [jobsAllowed, options]
  );

  const allCustomJobsShown = allCustomJobs.length === customJobs.length;

  return (
    <VehicleRequiredView
      data-testid="jobs"
      width="100%"
      hasOptions={options.length > 0}
      hasVehicle={hasVehicle}
      noOptionsIcon={<ImageFallback src={jobsImg} height="40px" width="40px" />}
      noVehicleText={
        customJobsEnabled && jobsAllowed
          ? 'A vehicle must be selected in order to use the jobs functionality. To add or view your created jobs, go to manage jobs.'
          : 'A vehicle must be selected in order to view jobs.'
      }
      noOptionsText="There are currently no jobs available for the vehicle you selected."
      maxHeight={104}
      height={104}
      extraButtons={[
        <Button key={1} onClick={onSelectVehicleClick} variant="secondary">
          Select Vehicle
        </Button>,
        customJobsEnabled && jobsAllowed && (
          <Button as={<Link to={Routes.MANAGE_CUSTOM_JOBS} />} leadingIcon="settings" key={2} variant="text">
            Manage jobs
          </Button>
        ),
      ]}
    >
      <Scrollbar>
        {customJobsEnabled ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mt={vehicleSpecificJobsEnabled ? 2 : 0}
          >
            <Box width="50%">
              {jobsAllowed && vehicleSpecificJobsEnabled && <VehicleSpecificJobsLabel />}
              {freeJobs.length > 0 && <FreeJobs jobs={freeJobs} onSelect={selectJob} />}
              <OptionsByColumns
                onChange={selectJob}
                onSelect={onSelect}
                options={paidJobs}
                maxColumns={1}
                isKeyboardDisabled={isKeyboardDisabled}
                title={!vehicleSpecificJobsEnabled ? 'popular' : undefined}
              />
            </Box>
            <Box width="50%">
              <Box display="flex" justifyContent="space-between" pl={{ sm: 1, md: 4 }}>
                <Title title="Custom" />
                {allCustomJobs.length > 0 &&
                  (isMobile ? (
                    <Button as={<Link to={Routes.MANAGE_CUSTOM_JOBS} />} variant="light" leadingIcon="settings" />
                  ) : (
                    <Button
                      as={<Link to={Routes.MANAGE_CUSTOM_JOBS} />}
                      variant="text"
                      size="dense"
                      leadingIcon="settings"
                    >
                      Manage
                    </Button>
                  ))}
              </Box>

              <OptionsByColumns
                onChange={selectJob}
                onSelect={onSelect}
                options={customJobs}
                maxColumns={1}
                isKeyboardDisabled={isKeyboardDisabled}
              />
              <Box maxWidth={87} mt={2} pl={{ sm: 1, md: 4 }}>
                {!allCustomJobsShown && (
                  <Banner key="banner" variant="info" icon="info" mb={2} dense>
                    Jobs may not appear if the parts don't match the vehicle.
                  </Banner>
                )}

                {!jobsAllowed ? (
                  <Button
                    key="upgrade-button"
                    color="upgrade"
                    leadingIcon="upgrade"
                    size="dense"
                    iconColor="secondaryDark10"
                    onClick={handleOpen}
                  >
                    Create job
                  </Button>
                ) : (
                  <Button
                    as={<Link to={Routes.CREATE_CUSTOM_JOB} />}
                    key="create-job-button"
                    variant={customJobs.length > 0 ? 'secondary' : 'primary'}
                    size="dense"
                    leadingIcon="add"
                  >
                    Create job
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box mt={vehicleSpecificJobsEnabled ? 2 : 0}>
            {jobsAllowed && vehicleSpecificJobsEnabled && <VehicleSpecificJobsLabel />}
            {freeJobs.length > 0 && <FreeJobs jobs={freeJobs} onSelect={selectJob} />}
            <OptionsByColumns
              onChange={selectJob}
              onSelect={onSelect}
              options={paidJobs}
              maxColumns={2}
              isKeyboardDisabled={isKeyboardDisabled}
            />
          </Box>
        )}
      </Scrollbar>
    </VehicleRequiredView>
  );
};
